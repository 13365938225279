<template>
    <div class="c_content_container contact">
        <!-- 상단배너 START -->
        <section class="c_section c_banner banner_about">
            <div class="c_inner">
                <div class="img_wrapper about">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_about.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section ci_content">
            <div class="c_inner">
                <div class="history">
                    <h1 class="section_title">{{ $t('History') }}</h1>
                    <p class="section_text">
                        {{ $t('History Txt') }}
                    </p>
                    <ul class="count_wrap" v-slidein:reverse.child="{
                        delay: 200,
                        percent: '-10%',
                        threshold: 0.1,
                    }">
                        <li>
                            <h3>Clients</h3>
                            <h1>15</h1>
                            <p>
                            <pre>{{ $t('Clients Txt') }}</pre>
                            </p>
                        </li>
                        <li>
                            <h3>Projects</h3>
                            <h1>19</h1>
                            <p>
                            <pre>{{ $t('Projects Txt') }}</pre>
                            </p>
                        </li>
                        <li>
                            <h3>Since</h3>
                            <h1>2021</h1>
                            <p>
                            <pre>{{ $t('Since Txt') }}</pre>
                            </p>
                        </li>
                    </ul>
                    <div class="timeline_wrap">
                        <div>
                            <h1 class="year">2022</h1>
                            <div class="content_wrap">
                                <div class="circle"></div>
                                <ul v-fadein:!reverse.child="{ delay: 200 }">
                                    <li>
                                        <h3>{{ $t('Papier') }}</h3>
                                        <p>- {{ $t('Dsc About Print') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Korea Payment System') }}</h3>
                                        <p>- {{ $t('Dsc About Giveheart') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Eum') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>DCR&CH</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Iptk') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Kfo') }}</h3>
                                        <p>- CMS {{ $t('Dsc About Back End') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Sonagimall') }}</h3>
                                        <p>- {{ $t('Dsc About Closed Shpmall') }}</p>
                                    </li>
                                    <li>
                                        <h3>KT</h3>
                                        <p>
                                            - VAN{{ $t('Service') }} B2B CRM {{ $t('Dsc About Back End') }}
                                        </p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Cj') }}</h3>
                                        <p>
                                            -{{ $t('Dsc About Cj') }}
                                        </p>
                                    </li>
                                    <li>
                                        <h3>SKT</h3>
                                        <p>
                                            - {{ $t('Dsc About Skt') }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h1 class="year">2021</h1>
                            <div class="content_wrap">
                                <div class="circle"></div>
                                <ul v-fadein:!reverse.child="{ delay: 200 }">
                                    <li>
                                        <h3>{{ $t('We Are Owner') }}</h3>
                                        <p>- {{ $t('Dsc About Startup') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Mpc') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Lotte') }}</h3>
                                        <p>
                                            - {{ $t('Dsc About Lotte') }}
                                        </p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Good Printing') }}</h3>
                                        <p>- {{ $t('Dsc About Print 02') }} {{ $t('Dsc About Back End') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Meta Buy Sell') }}</h3>
                                        <p>- {{ $t('Dsc About Meta') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Ton') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('New Flex') }}</h3>
                                        <p>- {{ $t('Dsc About Responsive Shpmall') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Vinacts') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                    <li>
                                        <h3>{{ $t('Papier') }}</h3>
                                        <p>- {{ $t('Dsc About Pr Web') }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/about/history.css"></style>
